.tooltip-input {
  display: flex;
  flex: 1;
  position: relative;
  align-items: center;
}

.tooltip-input__input {
  padding: 10px 15px;
  flex: 1;
  margin: 5px 0px;
  border: 1px solid #1a8cff;
  border-radius: 50px;
  font: inherit;
  background: white;
  font-size: 12px;
}

.tooltip-input__text {
  font-size: 15px;
  font-weight: bold;
  margin-left: 5px;
  color: #1a8cff;
  cursor: help;
  position: relative;
}

.tooltip-input__tooltip {
  visibility: hidden;
  background: black;
  color: white;
  font-weight: normal;
  font-size: 10px;
  border-radius: 6px;
  padding: 5px;
  position: absolute;
  bottom: 100%;
  left: 50%;
  width: 250px;
}

.tooltip-input__text:hover .tooltip-input__tooltip {
  visibility: visible;
}
