.version-info {
  text-align: center;
  font-size: 0.75rem;
}

.version-info-anchor,
.version-info-anchor:visited {
  color: hsl(210, 99%, 40%);
  text-decoration: none;
}
