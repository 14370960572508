.collapsible-content {
  display: flex;
  flex-direction: column;
  padding: 30px 44px;
  border-bottom: 1px solid hsla(0, 0%, 59%, 0.2);
}

.collapsible-content__bar {
  align-items: center;
  display: grid;
  grid-template-columns: 50px 1fr;
  justify-items: flex-start;
}

.collapsible-content__button {
  color: hsl(210, 99%, 40%);
  border: 1px solid hsla(0, 0%, 59%, 0.2);
  border-radius: 50%;
  width: 24px;
  height: 24px;
  /* background: #50b347; */
  cursor: pointer;
  font-size: 18px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.collapsible-content__heading {
  font-size: 16px;
  line-height: 25px;
}

.collapsed {
  display: none;
}

.expanded {
  display: block;
}
