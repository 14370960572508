.workspace-settings {
  display: flex;
  flex-direction: column;
}

.workspace-settings__status {
  margin: 10px 0px;
  color: black;
}

.workspace-settings__text--error {
  margin-top: 1.5rem;
  color: #bf4343;
}

.workspace-settings__text--success {
  margin-top: 1.5rem;
  color: #008A00;
}

.workspace-settings__button {
  padding: 10px 15px;
  flex: 1;
  margin: 5px 0px;
  border: 1px solid #1a8cff;
  border-radius: 50px;
  font: inherit;
  background-color: #1a8cff;
  color: white;
  font-size: 15px;
  font-weight: bold;
  cursor: pointer;
}

.workspace-settings__button:hover {
  background: #0572ec;
}

.workspace-settings__button--delete {
  background: white;
  color: #1a8cff;
}

.workspace-settings__button--delete:hover {
  background: #e4e4e4;
}

.workspace-settings__button--disabled {
  background: darkgray;
  pointer-events: none;
  border: 1px solid grey;
}

.workspace-settings__button--disabled:hover {
  background: grey;
}

.workspace-settings__file-input {
  display: none;
}
