#logout {
  display: flex;
  justify-content: center;
}

#logout > button {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px 5px;
  background-color: #1a8cff;
  border: 1px solid #1a8cff;
}

#logout > button:hover {
  background-color: #0572ec;
  color: white;
  cursor: pointer;
}
